import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./redux/reducers";

const middleware = [thunk];

let devTools =
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
if (process.env.NODE_ENV === "prod" || process.env.NODE_ENV === "production" || !devTools) {
  devTools = (a) => a;
}

const store = createStore(
  rootReducer,
  undefined,
  compose(applyMiddleware(...middleware), devTools || null)
);

export default store;
