import { SELECT_DRUGGIST, REMOVE_DRUGGIST } from "./../actions/actionTypes";

const initState = {};

export default (state = initState, action) => {
  switch (action.type) {
    case SELECT_DRUGGIST:
      return action.payload;
    case REMOVE_DRUGGIST:
      return action.payload;
    default:
      return state;
  }
};
