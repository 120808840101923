import { ITEM_SAMPLES } from "./constant";

/**
 * Verify if the email of the user is present in the list of administrators
 * @param {String} email - email to test
 * @param {Object} listOfAdmin - Object that contains a list of all emails of administrators
 */
export const isAdministrator = (email, listOfAdmin) => {
  if (listOfAdmin.length > 0) {
    return listOfAdmin.some((item) => item.email === email);
  } else return false;
};

/**
 * Function that process the remaining
 * @param {Object} gifts - The object that contains the list of gifts
 * @param {Object} ceiling - Object that contains all remainings value
 */
export const calculRemaining = (gifts, ceiling) => {
  // Remaining is initialized with ceiling values
  let response = {
    supplyRemaining: ceiling.ceillingItemSupply,
    otherRemaining: ceiling.ceillingItemOther,
    restauRemaining: ceiling.ceillingRestauNumber,
    priceRestauRemaining: ceiling.ceillingRestauPrice,
    sampleRemaining: ceiling.ceillingSample,
  };
  gifts.listGifts.forEach((element) => {
    // If the gift is an item
    if (element.type === "fourniture de bureau") {
      response.supplyRemaining -= element.price;
    } else if (element.type === "autre produit ou service") {
      response.otherRemaining -= element.price;
    } else if (element.type === "restaurant") {
      response.restauRemaining -= 1;
      response.priceRestauRemaining -= element.price;
    } else if (ITEM_SAMPLES.includes(element.type)) {
      response.sampleRemaining -= element.price;
    }
  });
  return response;
};

/**
 * Convert JSON to CSV
 */
function convertToCSV(objArray) {
  var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
  var str = "";
  for (var i = 0; i < array.length; i++) {
    var line = "";
    for (var index in array[i]) {
      if (line !== "") line += ",";
      line += array[i][index];
    }
    str += line + "\r\n";
  }
  return str;
}

/**
 * Export data
 * @param {Array} headers - Name of all headers
 * @param {Object} items - Data
 * @param {String} fileTitle - Title of th file to export
 */
export function exportCSVFile(headers, items, fileTitle) {
  if (headers) {
    items.unshift(headers);
  }
  // Convert Object to JSON
  var jsonObject = JSON.stringify(items);
  var csv = convertToCSV(jsonObject);
  var exportedFilename = fileTitle + ".csv" || "export.csv";

  var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFilename);
  } else {
    var link = document.createElement("a");
    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportedFilename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
