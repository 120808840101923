import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AuthRoute from "./AuthRoute";
import ProtectedRoute from "./ProtectedRoute";

const HOCHome = AuthRoute(lazy(() => import("../views/Home")));
const HOCDashboard = AuthRoute(lazy(() => import("../views/Dashboard")));
const HOCItem = AuthRoute(lazy(() => import("../views/ItemDeclaration")));
const HOCMeal = AuthRoute(lazy(() => import("../views/MealDeclaration")));
const HOCSample = AuthRoute(lazy(() => import("../views/SampleDeclaration")));
const HOCAdmin = AuthRoute(lazy(() => import("../views/Administrator")));

const MainRoutes = (props) => {
  return (
    <Router history={props.history}>
      <Suspense fallback={<div>Chargement...</div>}>
        <Routes>
          <Route exact path={`/`} element={<HOCHome />} />
          <Route exact path={`/dashboard`} element={<HOCDashboard />} />
          <Route exact path={`/tools`} element={<HOCItem />} />
          <Route exact path={`/meal`} element={<HOCMeal />} />
          <Route exact path={"/sample"} element={<HOCSample />} />
          <Route
            exact
            path="/admin"
            element={<ProtectedRoute component={HOCAdmin} redirectTo="/" />}
          />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default MainRoutes;
