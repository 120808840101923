import axios from "axios";
import { getToken } from "../utils/configuration";

let token = getToken();
axios.defaults.headers.common = { Authorization: `bearer ${token}` };
/**
 * API Call
 * Get the list of all user admin according to year
 */

export const getAllUserAdmin = async () => {
  let res = {
    listItems: [],
    status: 0,
  };
  return axios
    .get(`${process.env.REACT_APP_API_URI}/api/v1/userAdmin-list`)
    .then(async (response) => {
      res.listItems = response.data.list;
      res.status = response.status;
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

/**
 * API Call
 * create user admin
 */

export const createUserAdmin = async (newEmail) => {
  let res = {
    text: "",
    status: 0,
  };

  let params = {
    email: newEmail, // string
    isActive: true,
  };
  return axios
    .post(`${process.env.REACT_APP_API_URI}/api/v1/userAdmin-create`, params)
    .then(async (response) => {
      res.status = response.status;
      res.text = response.data.text;
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

/**
 * API Call
 * disable user
 */

export const disableUserAdmin = async (userToDisable) => {
  let params = {
    email: userToDisable, // string
    isActive: false,
  };
  let res = {
    text: "",
    status: 0,
  };
  return axios
    .put(`${process.env.REACT_APP_API_URI}/api/v1/userAdmin-update`, params)
    .then(async (response) => {
      res.status = response.status;
      res.text = response.data.text;
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

/**
 * API Call
 * delete user admin
 */

export const deleteUserAdmin = async (userToDelete) => {
  let res = {
    text: "",
    status: 0,
  };
  let params = {
    email: userToDelete, // string
  };
  return (
    axios
      //leave this writting {params} in this request if not it will not work
      .delete(`${process.env.REACT_APP_API_URI}/api/v1/userAdmin-delete`, {
        params,
      })
      .then(async (response) => {
        res.status = response.status;
        res.text = response.data.text;
        return res;
      })
      .catch((err) => {
        return err.response;
      })
  );
};
