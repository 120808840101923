import { AuthenticationContext, adalFetch } from "react-adal";

/**
 * https://docs.microsoft.com/fr-fr/azure/active-directory/develop/scenario-web-app-sign-user-app-configuration?tabs=aspnetcore
 * https://docs.microsoft.com/fr-fr/azure/active-directory/develop/active-directory-v2-protocols
 * https://docs.microsoft.com/fr-fr/azure/active-directory/develop/v2-oauth2-auth-code-flow
 * https://anceret-matthieu.fr/2019/07/authentification-azure-ad-avec-reactjs/
 */

// ADAL configuration
export const adalConfig = {
  tenant: process.env.REACT_APP_TENANT_AZURE, // id ou url du tenant Azure
  clientId: process.env.REACT_APP_CLIENT_ID_AZURE, // id de l'application créé ci-dessus
  authority: process.env.REACT_APP_AZURE_AUTHORITY,
  endpoints: {
    api:
      "https://login.microsoftonline.com/consumers/cc0a4ff6-9454-4e4b-881b-85f448dee2e3",
  },
  redirectUri: process.env.REACT_APP_AZURE_REDIRECT, // url de redirection
  postLogoutRedirectUri: process.env.REACT_APP_POST_LOGOUT, // url appelée lors de la déconnexion
  cacheLocation: "localStorage",
};

export const authContext = new AuthenticationContext(adalConfig);

/**
 * Method used to get the token stored in the cache of the user
 */
export const getToken = () => {
  var cachedToken = authContext.getCachedToken(authContext.config.clientId);
  if (cachedToken === undefined) {
    authContext.acquireToken(authContext.config.clientId);
  } else {
    return cachedToken;
  }
};

/**
 * Method used to do a call (axios, fetch) using ADAL
 */
export const adalApiFetch = (fetch, url, options) =>
  adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);
