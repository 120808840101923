import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

/**
 * Protected route that check if the user is an admin
 * @param {Component} Component - The component to render
 */
export default function ProtectedRoute({ component: Component, redirectTo }) {
  const user = useSelector((state) => state.user);

  if (user && user.isAdmin) {
    return <Component />;
  } else {
    return <Navigate to={redirectTo} />;
  }
}
