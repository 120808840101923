import React from "react";
import { authContext, getToken } from "../utils/configuration";
import { isAdministrator } from "../utils/function";
import { getAllUserAdmin } from "../services/userAdmin";
import { connect } from "react-redux";
import { loginAction } from "../redux/actions/userActions";

const aad = require("azure-ad-jwt");
const util = require("util");

/**
 * HOC to check if the user is connected
 * @param {Component} Component - The component to render
 */
export default function AuthRoute(Component) {
  class Default extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        valideUser: false,
      };
    }
    async componentDidMount() {
      this.token = getToken();
      if (this.token !== undefined && (await this.verifyToken())) {
        this.setState({ valideUser: true });
      } else {
        this.setState({ valideUser: false });
        authContext.clearCache();
        authContext.login();
      }
    }

    /**
     * Function to determine if the user is admin or not
     * @param {Object} user - Object that contains user informations as email
     */
    determineAdministrator = async (user) => {
      const administrators = await getAllUserAdmin();
      if (administrators && administrators.status === 200) {
        if (isAdministrator(user.upn, administrators.listItems)) {
          user.isAdmin = true;
        } else {
          user.isAdmin = false;
        }
      }
      return user;
    };

    // Function to verify the JWT token
    verifyToken() {
      const verifyPromise = util.promisify(aad.verify);
      return verifyPromise(this.token, null)
        .then(async (result) => {
          const user = result;
          // Determine if the user is an administrator or not
          await this.determineAdministrator(user);
          // Modify the redux store if the objects are different
          if (
            Object.entries(user).toString() !==
            Object.entries(this.props.user).toString()
          ) {
            this.props.loginAction(user);
          }
          return true;
        })
        .catch((err) => {
          console.error(err);
          return false;
        });
    }

    render() {
      if (this.state.valideUser) return <Component {...this.props} />;
      else return null;
    }
  }

  const mapDispatchToProps = (dispatch) => ({
    loginAction: (user) => dispatch(loginAction(user)),
  });

  const mapStateToProps = (state) => ({
    user: state.user,
  });

  return connect(mapStateToProps, mapDispatchToProps)(Default);
}
