import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import "./custom.scss";
import "./../src/assets/scss/main.scss";

import { Provider } from "react-redux";
import store from "./store";
import history from "./history";
import MainRoutes from "./route/routes";

import { runWithAdal } from "react-adal";
import { authContext } from "./utils/configuration";

const DO_NOT_LOGIN = false;

runWithAdal(
  authContext,
  () => {
    ReactDOM.render(
      <Provider store={store}>
        <MainRoutes history={history} />
      </Provider>,
      document.getElementById("root")
    );
  },
  DO_NOT_LOGIN
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
